import resized from "@/javascript/components/events/resized"
import wait from "@/javascript/components/tools/wait"

const autoHandlers = {
  height: (elem) => elem.offsetHeight,
  width: (elem) => elem.offsetWidth
}

const getStyle = (element, property) => {
  let value = (element.computedStyles ||= getComputedStyle(element))[property]
  const handler = autoHandlers[property]

  if(value === 'auto' && handler) {
    value = handler(element)
  }

  return value
}

const fixStyle = (element, property, opts = {}) => {
  const value = getStyle(element, property)

  if(opts.except && opts.except.includes(value)) {
    return
  }

  element.style.setProperty(property, value)
  return value
}

export function slideDown (element, time) {
  const currentHeight = getStyle(element, 'height')
  const startDisplay = fixStyle(element, 'display', {except: ['none']})

  return Promise.resolve()
    .then(function() {
      element.style.setProperty('overflow', 'hidden')
      element.style.setProperty('display', 'block')
      if (currentHeight == 0 || !element.dataset.targetHeight){
        element.classList.remove('slide-up-down')
        element.style.setProperty('visibility', 'hidden')
        element.style.removeProperty('height')
        element.dataset.targetHeight = getStyle(element, 'height')
        element.style.removeProperty('visibility')
        element.style.setProperty('--transitionTime' , '0ms')
        element.style.setProperty('--targetHeight' , '0px')
        element.classList.add('slide-up-down')
      }
    })
    .then(() => wait(1)) // wait for browser to set initial height
    .then(() => {
      element.style.setProperty('--transitionTime' , time + 'ms')
      element.style.setProperty('--targetHeight' , element.dataset.targetHeight)
      element.classList.add('slide-up-down')
      element.style.setProperty('display', startDisplay)
    })
}

export function slideUp (element, time) {
  return Promise.resolve()
    .then(function() {
      element.style.setProperty('overflow', 'hidden')
      element.style.setProperty('--transitionTime' , time + 'ms')
      element.style.setProperty('--targetHeight' , '0px')
      element.classList.add('slide-up-down')
    })
    .then(() => wait(time))
    .then(() => {
      element.style.setProperty('display', 'none')
    })
}

// TODO: get rid of this when remove_block.js.erb is removed
window.slideUp = slideUp

export function slideToggle (element, time) {
  if(element.offsetHeight) {
    return slideUp(element, time)
  } else {
    return slideDown(element, time)
  }
}

export function slideTransition (element, time, promiseOrFunction) {
  const startHeight = fixStyle(element, 'height')
  const startWidth = fixStyle(element, 'width')
  const startDisplay = fixStyle(element, 'display', {except: ['none']})

  if(!element.animationEndEnabled) {
    element.animationEndEnabled = true
    element.addEventListener('animationend', (e) => {
      element.style.removeProperty('hidden')
      element.style.removeProperty('display')
      element.classList.remove('slide-transition')
      resized(element)
    })
  }

  return Promise.resolve()
    .then(function() {
      element.style.setProperty('overflow', 'hidden')
      element.classList.remove('slide-transition')
    })
    .then(() => {
      if(typeof(promiseOrFunction) == 'function'){
        return promiseOrFunction()
      } else {
        return promiseOrFunction
      }
    })
    .then(() => wait(1))
    .then(() => {
      element.style.removeProperty('height')
      element.style.removeProperty('width')
      element.style.removeProperty('display')
      const targetHeight = getStyle(element, 'height')
      const targetWidth = getStyle(element, 'width')
      element.style.setProperty('display', startDisplay)
      element.style.setProperty('--startHeight' , startHeight)
      element.style.setProperty('--startWidth' , startWidth)
      element.style.setProperty('--targetHeight' , targetHeight)
      element.style.setProperty('--targetWidth' , targetWidth)
      element.style.setProperty('--transitionTime' , time + 'ms')

      element.classList.add('slide-transition') // start animation
    })
    .then(() => wait(time))
    .then(() => {
      element.style.removeProperty('display')
      element.style.removeProperty('overflow')
      element.classList.remove('slide-transition')
    })
}

